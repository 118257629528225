import { Button } from "antd";

export default function Custom404({ data }) {
    return <div style={{ paddingTop: "25vh", textAlign: "center" }}>
        <h1>404 - Page Not Found</h1>
        <h2>OOPS！</h2>
        <Button onClick={() => {
            window.location = "https://intellieff.top";
        }}>返回首页</Button>
    </div>
}

export async function getStaticProps() {
    return {
        props: {
            page: "404-page",
            // data: res.data,
        },
    };
}